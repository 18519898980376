import React, { Component } from 'react'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './scss/style.scss'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import User from './User'

import ApolloClient from 'apollo-boost'
import { ApolloProvider } from 'react-apollo'
import { InMemoryCache } from 'apollo-cache-inmemory'
import Cookies from 'js-cookie'

// let url = 'http://35.243.79.189:4000'
let url = 'https://us-central1-customermanagement-285703.cloudfunctions.net/gql'

const client = new ApolloClient({
    uri: url,
    cache: new InMemoryCache(),
    request: (operation) => {
        operation.setContext({
            headers: {
                // authorization: 'Bearer ' + code
                'X-CSRFToken': Cookies.get('csrftoken'),
                'X-Requested-With': 'XMLHttpRequest',
            },
        })
    },
    onError: ({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            // graphQLErrors固有の処理
            console.log('[GraphQL error]')
            console.dir(graphQLErrors)
        }
        if (networkError) {
            // networkError固有の処理
            console.log('[Network error]')
            console.dir(networkError)
        }
    },
})

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
// const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
    render() {
        if (User.isLoggedIn()) {
            return (
                <HashRouter>
                    <React.Suspense fallback={loading}>
                        <ApolloProvider client={client}>
                            <Switch>
                                <Route
                                    path="/"
                                    name="Home"
                                    render={(props) => <TheLayout {...props} />}
                                />
                            </Switch>
                        </ApolloProvider>
                    </React.Suspense>
                </HashRouter>
            )
        } else {
            return (
                <HashRouter>
                    <React.Suspense fallback={loading}>
                        <Switch>
                            <Route
                                exact
                                path="/"
                                name="Login Page"
                                render={(props) => <Login {...props} />}
                            />
                            {/* <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} /> */}
                            <Route
                                exact
                                path="/404"
                                name="Page 404"
                                render={(props) => <Page404 {...props} />}
                            />
                            <Route
                                exact
                                path="/500"
                                name="Page 500"
                                render={(props) => <Page500 {...props} />}
                            />
                            <Redirect to="/" />
                        </Switch>
                    </React.Suspense>
                </HashRouter>
            )
        }
    }
}

export default App
