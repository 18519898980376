class User {
    isLoggedIn = () => this.get('isLoggedIn') === 'true'
    isRole = () => this.get('isRole')
    isShop = () => this.get('isShop')
    isID = () => this.get('isID')

    isInfo = (email) => {
        const users = [
            {
                role: '店舗',
                shop: 'TNC',
                // shop: 'REVIA',
                email: 'k.toshio.8008@gmail.com',
            },
            {
                role: '店舗',
                shop: 'DEAREST',
                email: 'keita.00661188@gmail.com',
            },
            {
                role: '店舗2',
                shop: '心',
                email: 'g.shinpei@gmail.com',
            },
            {
                role: '店舗',
                shop: 'DEAREST',
                email: 'sorosoro.iichao@gmail.com',
            },
            {
                role: '店舗',
                shop: 'DEAREST',
                email: 'ryo.dearest@gmail.com',
            },
            {
                role: '店舗',
                shop: '妃翠',
                email: 'hisui.roppongi@gmail.com',
            },
            {
                role: '店舗',
                shop: '妃翠',
                email: 'vinotheque1995@gmail.com',
            },
            {
                role: '店舗',
                shop: 'VEROUGE',
                email: 'shunsuke9891@gmail.com',
            },
            {
                role: '店舗',
                shop: 'VEROUGE',
                email: 'a08034037865a@gmail.com',
            },
            {
                role: '店舗',
                shop: 'JELIGHT',
                email: 'yasuaki.08035299423@gmail.com',
            },
            {
                role: '店舗3',
                shop: 'JELIGHT',
                email: 'atsushitakeda11071967@gmail.com',
            },
            {
                role: '店舗',
                shop: '朝JELIGHT',
                email: 'shin.is.0812@gmail.com',
            },
            {
                role: '店舗',
                shop: 'SOIREE',
                email: 'malon5923@gmail.com',
            },
            {
                role: '店舗',
                shop: 'SOIREE',
                email: 'harutomegumi874628@gmail.com',
            },
            {
                role: '店舗',
                shop: 'EBSG',
                email: 'abeshunchildren@gmail.com',
            },
            {
                role: '店舗',
                shop: 'EBSG',
                email: 'gin.gin.gin.072@gmail.com',
            },
            {
                role: '店舗',
                shop: 'REVIA',
                email: 'takuyayamasaki830@gmail.com',
            },
            {
                role: '店舗',
                shop: 'REVIA',
                email: 't.masaya.9696@gmail.com',
            },
            {
                role: '店舗',
                shop: 'REVIA',
                email: 'sshonituka1986@gmail.com',
            },
            {
                role: '店舗',
                shop: 'EXSULLY',
                email: 'akixx22@gmail.com',
            },
            {
                role: '管理者',
                shop: '全店舗',
                email: 'doraemons1114@gmail.com',
            },
            {
                role: '管理者',
                shop: '全店舗',
                email: 'luminafeel@gmail.com',
            },
        ]
        let flag = false
        users.map((user) => {
            if (user.email === email) {
                flag = user
                this.set('isShop', user.shop)
                this.set('isRole', user.role)
            }
        })
        return flag
    }

    set = (key, value) => localStorage.setItem(key, value)

    get = (key) => this.getLocalStorage(key)

    getLocalStorage = (key) => {
        const ret = localStorage.getItem(key)
        if (ret) {
            return ret
        }
        return null
    }

    login = (email, password) => {
        const users = [
            {
                role: '店舗',
                shop: 'REVIA',
                email: 'k.toshio.8008@gmail.com',
                pass: 's7kbrp8e',
            },
            {
                role: '店舗',
                shop: 'DEAREST',
                email: 'keita.00661188@gmail.com',
                pass: 'p5hk3qgy',
            },
            {
                role: '店舗',
                shop: 'DEAREST',
                email: 'g.shinpei@gmail.com',
                pass: 'c27v6iph',
            },
            {
                role: '店舗',
                shop: 'DEAREST',
                email: 'sorosoro.iichao@gmail.com',
                pass: 'g78dbn5f',
            },
            {
                role: '店舗',
                shop: 'DEAREST',
                email: 'ryo.dearest@gmail.com',
                pass: 's24atu3c',
            },
            {
                role: '店舗',
                shop: '妃翠',
                email: 'hisui.roppongi@gmail.com',
                pass: 'i6vht4by',
            },
            {
                role: '店舗',
                shop: '妃翠',
                email: 'vinotheque1995@gmail.com',
                pass: 'n5ds8xjh',
            },
            {
                role: '店舗',
                shop: 'VEROUGE',
                email: 'shunsuke9891@gmail.com',
                pass: 's8qy5fh9',
            },
            {
                role: '店舗',
                shop: 'VEROUGE',
                email: 'a08034037865a@gmail.com',
                pass: 'z7rctwf3',
            },
            {
                role: '店舗',
                shop: 'JELIGHT',
                email: 'yasuaki.08035299423@gmail.com',
                pass: 'i7yxcgew',
            },
            {
                role: '店舗',
                shop: 'JELIGHT',
                email: 'atsushitakeda11071967@gmail.com',
                pass: 'z9wvhdbs',
            },
            {
                role: '店舗',
                shop: '朝JELIGHT',
                email: 'shin.is.0812@gmail.com',
                pass: 'j2k9ivdm',
            },
            {
                role: '店舗',
                shop: 'SOIREE',
                email: 'malon5923@gmail.com',
                pass: 'f5xs3puy',
            },
            {
                role: '店舗',
                shop: 'SOIREE',
                email: 'harutomegumi874628@gmail.com',
                pass: 'v6yegrax',
            },
            {
                role: '店舗',
                shop: 'EBSG',
                email: 'abeshunchildren@gmail.com',
                pass: 'b7nigu3x',
            },
            {
                role: '店舗',
                shop: 'EBSG',
                email: 'gin.gin.gin.072@gmail.com',
                pass: 'z654nyiw',
            },
            {
                role: '店舗',
                shop: 'REVIA',
                email: 'takuyayamasaki830@gmail.com',
                pass: 'n632jfhi',
            },
            {
                role: '店舗',
                shop: 'REVIA',
                email: 't.masaya.9696@gmail.com',
                pass: 'w2iea5yr',
            },
            {
                role: '店舗',
                shop: 'REVIA',
                email: 'sshonituka1986@gmail.com',
                pass: 'q4cmzun6',
            },
            {
                role: '店舗',
                shop: 'EXSULLY',
                email: 'akixx22@gmail.com',
                pass: 'x62w5tgh',
            },
            {
                role: '管理者',
                shop: '全店舗',
                email: 'admin',
                pass: 'luminafeel001',
            },
            // {
            //     role: '個人',
            //     email: 't.y09054845733@gmail.com',
            //     id: '11',
            //     pass: 'm25hp3j6',
            // },
            // {
            //     role: '個人',
            //     email: 'aaarthur116@gmail.com',
            //     id: '12',
            //     pass: 'g8awpb4z',
            // },
            // {
            //     role: '個人',
            //     email: 'kanekokanji.1022@gmail.com',
            //     id: '13',
            //     pass: 'x8ztugd5',
            // },
            // {
            //     role: '個人',
            //     email: '0ff4c450329163d@gmail.com',
            //     id: '14',
            //     pass: 'r8pshcet',
            // },
            // {
            //     role: '個人',
            //     email: 'nao51122415@gmail.com',
            //     id: '27',
            //     pass: 'u94zv6yk',
            // },
            // {
            //     role: '個人',
            //     email: 'lemonrai4444@gmail.com',
            //     id: '41',
            //     pass: 'g4fx3ruq',
            // },
            // {
            //     role: '個人',
            //     email: 'rsk.k.r.0413@gmail.com',
            //     id: '42',
            //     pass: 'q5c24769',
            // },
            // {
            //     role: '個人',
            //     email: 'banbenling366@gmail.com',
            //     id: '43',
            //     pass: 'h347zjmu',
            // },
            // {
            //     role: '個人',
            //     email: 'ichi23456789@gmail.com',
            //     id: '62',
            //     pass: 'd4scvpwb',
            // },
            // {
            //     role: '個人',
            //     email: 'zzfhpc6ra@gmail.com',
            //     id: '71',
            //     pass: 'v34amuce',
            // },
            // {
            //     role: '個人',
            //     email: 'ouka.ranman.s.e.b@gmail.com',
            //     id: '72',
            //     pass: 's4mc72h9',
            // },
            // {
            //     role: '個人',
            //     email: 'yoshimasa916@gmail.com',
            //     id: '86',
            //     pass: 'a5qnyisg',
            // },
            // {
            //     role: '個人',
            //     email: 'shinobuokubo@gmail.com',
            //     id: '87',
            //     pass: 'c8vjdr9x',
            // },
            // {
            //     role: '個人',
            //     email: 'birikendesuyo@gmail.com',
            //     id: '16',
            //     pass: 'z6vu9eks',
            // },
            // {
            //     role: '個人',
            //     email: 'fukasawa49580402@gmail.com',
            //     id: '17',
            //     pass: 'e6yr5fsq',
            // },
            // {
            //     role: '個人',
            //     email: 'pengzaipogu@gmail.com',
            //     id: '18',
            //     pass: 'r3mupiwy',
            // },
            // {
            //     role: '個人',
            //     email: 'umedora0702@gmail.com',
            //     id: '28',
            //     pass: 'y956umre',
            // },
            // {
            //     role: '個人',
            //     email: 'sky48693rdl@gmail.com',
            //     id: '55',
            //     pass: 'c3fqtekh',
            // },
            // {
            //     role: '個人',
            //     email: 'sh.am70323@gmail.com',
            //     id: '30',
            //     pass: 'p8gbcqym',
            // },
            // {
            //     role: '個人',
            //     email: 'thousand1090@gmail.com',
            //     id: '44',
            //     pass: 'r95v8wyt',
            // },
            // {
            //     role: '個人',
            //     email: 'ryuji19841018@gmail.com',
            //     id: '57',
            //     pass: 'i3nraqvf',
            // },
            // {
            //     role: '個人',
            //     email: 'toshiomi0614@gmail.com',
            //     id: '63',
            //     pass: 'a5gqnjmb',
            // },
            // {
            //     role: '個人',
            //     email: 'haizhanggi576@mail.com',
            //     id: '54',
            //     pass: 'c3mtj5xe',
            // },
            // {
            //     role: '個人',
            //     email: 'hiro9n0623@gmail.com',
            //     id: '64',
            //     pass: 't3zm7n2a',
            // },
            // {
            //     role: '個人',
            //     email: 'k2k2k2184@gmail.com',
            //     id: '73',
            //     pass: 'a8smqkre',
            // },
            // {
            //     role: '個人',
            //     email: 'goki510yama@gmail.com',
            //     id: '82',
            //     pass: 'i3rtspge',
            // },
        ]

        for (let user in users) {
            if (email === users[user].email && password === users[user].pass) {
                this.set('isLoggedIn', true)
                this.set('isShop', users[user].shop)
                this.set('isRole', users[user].role)
                if (users[user].id) {
                    this.set('isID', users[user].id)
                }
                location.reload()
                return true
            }
        }
        this.set('isLoggedIn', false)
        return false
    }
}

export default new User()
